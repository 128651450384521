<!-- <template>
  <div class="login-container">
    <div class="login-card">
      <h2 class="login-title">实验室考勤系统登录</h2>
      <el-form ref="form" :model="form" class="login-form">
        <el-form-item class="form-item">
          <el-input v-model="form.id" placeholder="请输入账号" prefix-icon="el-icon-user" class="custom-input"></el-input>
        </el-form-item>

        <el-form-item class="form-item">
          <el-input type="password" v-model="form.password" placeholder="请输入密码" prefix-icon="el-icon-lock"
            class="custom-input" show-password></el-input>
        </el-form-item>

        <el-form-item class="role-select">
          <el-radio-group v-model="form.actor">
            <el-radio-button label="administrator">管理员</el-radio-button>
            <el-radio-button label="student">学生</el-radio-button>
            <el-radio-button label="teacher">教师</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item class="action-buttons">
          <el-button type="primary" @click="login" class="login-btn">登录</el-button>
          <div class="register-links">
            <router-link to="/register-student">学生注册</router-link>
            <span class="divider">|</span>
            <router-link to="/register-teacher">教师注册</router-link>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
export default {
  data() {
    return {
      form: {
        id: '',
        password: '',
        actor: 'student'
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    login() {
      let endpoint = "/login/" + this.form.actor;
      let inputid = this.form.actor == "student" ? this.form.id : +this.form.id;
      console.log(inputid);
      request({
        url: endpoint,
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          id: inputid,
          password: this.form.password
        }
      }).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.$store.commit('updateCurrentUser', res.data);
          this.$router.push('/' + this.form.actor);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(() => {
        this.$message.error('服务器错误');
      });
    },
  }
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.login-card {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 420px;
  transition: transform 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
}

.login-title {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.login-form .form-item {
  margin-bottom: 25px;
}

.login-form .form-item .el-input__inner {
  height: 48px;
  border-radius: 8px;
  padding-left: 40px;
  transition: all 0.3s ease;
}

.login-form .form-item .el-input__inner:focus {
  border-color: #409eff;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.2);
}

.login-form .form-item .el-input__prefix {
  left: 12px;
}

.login-form .form-item .el-input__prefix i {
  font-size: 18px;
  color: #909399;
}

.role-select {
  margin: 20px 0;
}

.role-select .el-radio-button {
  flex: 1;
  text-align: center;
}

.role-select .el-radio-button .el-radio-button__inner {
  width: 100%;
  border-radius: 6px !important;
  transition: all 0.3s ease;
}

.action-buttons {
  margin-top: 30px;
  text-align: center;
}

.action-buttons .login-btn {
  width: 100%;
  height: 48px;
  font-size: 16px;
  border-radius: 8px;
  letter-spacing: 2px;
}

.action-buttons .register-links {
  margin-top: 20px;
  font-size: 14px;
}

.action-buttons .register-links a {
  color: #606266;
  transition: color 0.3s ease;
}

.action-buttons .register-links a:hover {
  color: #409eff;
}

.action-buttons .register-links .divider {
  margin: 0 12px;
  color: #dcdfe6;
}
</style> -->
<template>
  <div class="login-container">
    <h2>实验室考勤系统登录</h2>
    <el-form ref="form" :model="form" label-width="auto">
      <el-form-item class="elformitem" >
        <el-input v-model="form.id" placeholder="请输入账号">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </el-form-item >
      <el-form-item  class="elformitem">
        <el-input type="password" v-model="form.password" placeholder="请输入密码">
          <i slot="prefix" class="el-input__icon el-icon-key" ></i>
        </el-input>
      </el-form-item>
      <el-form-item class="elformitem">
        <el-radio-group v-model="form.actor">
          <el-radio label="administer" >管理员</el-radio>
          <el-radio label="student" >学生</el-radio>
          <el-radio label="teacher" >教师</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">登陆</el-button>
        <el-button type="primary" @click="register">注册</el-button>
      </el-form-item>
    </el-form>
    <div class="register-links">
      <router-link to="/register-student">注册学生</router-link> |
      <router-link to="/register-teacher">注册教师</router-link>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
export default {
  data() {
    return {
      form: {
        id: '',
        password: '',
        actor: 'student'
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    login() {
      let endpoint="/login/"+this.form.actor;
      let inputid=this.form.actor=="student"? this.form.id: +this.form.id;
      console.log(inputid);
      request({
        url: endpoint,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          id: inputid,
          password: this.form.password
        }
      }).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.$store.commit('updateCurrentUser', res.data);
          this.$router.push('/' + this.form.actor);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(() => {
        this.$message.error('服务器错误');
      });
    },
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('/src/assets/bg.webp') no-repeat center center/cover;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 10px;
  border: 6px solid #000;
}
input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

i{
  color: #ccc;
  font-size: 20px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

.register-links {
  margin-top: 10px;
}

.register-links a {
  color: #007bff;
  text-decoration: none;
}
</style>


