<template>
    <div>
        <el-container>
            <el-header height="70px">
                <div class="header-left">
                    <img src="../assets/ZNMD.png" alt="logo" />
                    <span>考勤管理系统</span>
                </div>
                <div class="header-right">
                    <strong style="color: red; margin-right: 50px;">欢迎学生 {{currentuser.studentname}} ，学号：{{ currentuser.studentid }} 使用本系统</strong>
                    <el-button type="text" @click="logout">退出</el-button>
                </div>
            </el-header>
            <el-container>
                <el-aside width="199px">
                    <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect"
                        background-color="#545c64" router text-color="#fff" active-text-color="#ffd04b">
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span>考勤管理</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="/student/checks">考勤记录</el-menu-item>
                                <el-menu-item index="/student/chart">考勤统计</el-menu-item>
                                <el-menu-item index="/student/replace">补卡申请</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-menu-item index="/student/application">
                            <i class="el-icon-menu"></i>
                            <span slot="title">请假申请</span>
                        </el-menu-item>
                            <el-menu-item index="/student/information">
                            <i class="el-icon-setting"></i>
                            <span slot="title">个人信息</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                    <el-main>
                        <router-view class="routerview"></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
export default {
    name:"studentIndex",
    methods: {
        logout() {
            this.$router.push("/");
        },
    },
    computed: {
        currentuser() {
            return this.$store.state.currentuser;
        }
    }
};
</script>

<style scoped>
.el-header {
    height: 300px;
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.el-container:first-child {
    height: 100vh;
}

.el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
}

.el-header img {
    width: 60px;
    height: 60px;
}

.el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
}
.student {
    width: 100%;
    height: 100%;
}
.header-left {
    display: flex;
    align-items: center;
}

.header-left span {
    font-size: 16px;
    margin-left: 10px;
}
</style>