
// 引入各个页面组件
import IndexPage from '../views/IndexPage.vue';
import StudentIndex from '../views/StudentIndex.vue';
import TeacherIndex from '../views/TeacherIndex.vue';
import AdministerIndex from '../views/AdministerIndex.vue';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexPage,
  },
  {
    path: '/student',
    component: StudentIndex,
    name: 'student',
    children: [
      {
        path: 'checks',
        name: 'checks',
        component: () => import('../components/StudentChecks.vue'),
      },
      {
        path:'chart',
        name: 'chart',
        component: () => import('../components/StudentChart.vue'),
      },
      {
        path:'replace',
        name: 'replace',
        component: () => import('../components/StudentReplace.vue'),
      },
      {
        path: 'application',
        name: 'application',
        component: () => import('../components/StudentApplication.vue'),
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('../components/StudentInformation.vue'),
      },
      {
        path:'',
        name: 'chart',
        component: () => import('../components/StudentChart.vue'),
      }
    ]
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: TeacherIndex,
    children: [
      {
        path: 'checks',
        name: 'checks',
        component: () => import('../components/TeacherChecks.vue'),
      },
      {
        path:'chart',
        name: 'chart',
        component: () => import('../components/TeacherChart.vue'),
      },
      {
        path:'replace',
        name: 'replace',
        component: () => import('../components/TeacherReplace.vue'),
      },
      {
        path:'time',
        name: 'time',
        component: () => import('../components/TeacherTime.vue'),
      },
      {
        path: 'application',
        name: 'application',
        component: () => import('../components/TeacherApplication.vue'),
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('../components/TeacherInformation.vue'),
      },
      {
        path:'',
        name: 'chart',
        component: () => import('../components/TeacherChart.vue'),
      }
    ]
  },
  {
    path: '/administer',
    name:'administer',
    component: AdministerIndex,
    children: [
      {
        path:'addRoom',
        name: 'addRoom',
        component: () => import('../components/AdministerAddRoom.vue'),
      },
      {
        path:'addTeacher',
        name: 'addTeacher',
        component: () => import('../components/AdministerAddTeacher.vue'),
      }
    ]
  },
  {
    path:'/register-student',
    name: 'register-student',
    component: () => import('../views/RegisterStudent.vue'),
  }
];

const router = new Router({
  mode: 'history',
  routes,
});

// // 路由守卫：检查是否需要认证
// router.beforeEach((to) => {
//   // 如果没有token, 且访问的是非登录页，拦截到登录，其他情况正常放行
//   const useStore = useUserStore()
//   if (!useStore.token && to.path !== '/login') return '/login'
// })

export default router;
