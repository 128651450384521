<template>
    <div>
        <el-container>
            <el-header height="70px">
                <div class="header-left">
                    <img src="../assets/ZNMD.png" alt="logo" />
                    <span>考勤管理系统</span>
                </div>
                <div class="header-right">
                    <strong style="color: red; margin-right: 50px;">欢迎管理员 {{currentuser.administername}} ，工号：{{ currentuser.administerid }} 使用本系统</strong>
                    <el-button type="text" @click="logout">退出</el-button>
                </div>
            </el-header>
            <el-container>
                <el-aside width="199px">
                    <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect"
                        background-color="#545c64" router text-color="#fff" active-text-color="#ffd04b">
                        <el-menu-item index="/administer/addRoom">
                            <i class="el-icon-menu"></i>
                            <span slot="title">新增实验室</span>
                        </el-menu-item>
                            <el-menu-item index="/administer/addTeacher">
                            <i class="el-icon-setting"></i>
                            <span slot="title">注册教师</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                    <el-main>
                        <router-view class="routerview"></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
export default {
    name:"administerIndex",
    methods: {
        logout() {
            this.$router.push("/");
        },
    },
    computed: {
        currentuser() {
            return this.$store.state.currentuser;
        }
    }
};
</script>

<style scoped>
.el-header {
    height: 300px;
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.el-container:first-child {
    height: 100vh;
}

.el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
}

.el-header img {
    width: 60px;
    height: 60px;
}

.el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
}
.student {
    width: 100%;
    height: 100%;
}
.header-left {
    display: flex;
    align-items: center;
}

.header-left span {
    font-size: 16px;
    margin-left: 10px;
}
</style>