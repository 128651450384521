// 导入 vue
import Vue from 'vue'
// 导入 vuex
import Vuex from 'vuex'
// vuex也是vue的插件, 需要use一下, 进行插件的安装初始化
Vue.use(Vuex)

// 创建仓库 store
const store = new Vuex.Store({
    strict: true,
    state: {
        // 仓库中的数据
        count: 3242342342,
        currentuser:JSON.parse(localStorage.getItem('currentuser'))|| {
            
        }
    },
    mutations: {    
        updateCurrentUser(state, user) {
            state.currentuser = user
            localStorage.setItem('currentuser', JSON.stringify(user));
        },
        updateCurrentUserImage(state, image) {
            state.currentuser.image = image
            localStorage.setItem('currentuser', JSON.stringify(state.currentuser));
            console.log(state.currentuser.image)
        },
        // 仓库中的方法
        increment(state) {
            state.count++
        }
    },
    getters: {
        // 仓库中的计算属性
        doubleCount(state) {
            return state.count * 2
        }
    },   
    actions : {
        // 仓库中的异步方法
        incrementAsync(context) {
            setTimeout(() => {
                context.commit('increment')
            }, 1000)
        }
    }
})

// 导出仓库
export default store